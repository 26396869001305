import { partition } from "lodash";
import { initializeTeamCheckFeedbackModal } from "@/scripts/faculty/team-check-feedback.js";
import { initializeTeamCheckFeedback } from "@/scripts/tc-feedback.js";
import { renderIndividualGraph } from "@/scripts/individual-viz.js";
import { initializeIndividualTeamCheckDetails } from "@/scripts/student/individual-team-check-details.js";
import { initializeCommentModal } from "@/scripts/faculty/comments.js";
import { evalOverviewGraph } from "@/scripts/faculty/eval-overview-viz.js";
import { renderMemberGraphs } from "@/scripts/student/render-member-graphs.js";
import { renderPerformanceGraph } from "@/scripts/performance-viz-team-modal.js";
import { initializeGrantCredit } from "@/scripts/faculty/grant-credit.js";
import { initializeForm as initializeResetAssignmentForm } from "@/scripts/faculty/reset-assignment.js";

const CALLBACKS = {
  "team-check-feedback": initializeTeamCheckFeedbackModal,
  "team-check-details": initializeTeamCheckFeedback,
  "individual-details": renderIndividualGraph,
  "team-check-feedback-individual": initializeIndividualTeamCheckDetails,
  "eval-overview-graph": evalOverviewGraph,
  "render-member-graphs": renderMemberGraphs,
  "team-details": renderPerformanceGraph,
  "grant-credit": initializeGrantCredit,
  "reset-assignment": initializeResetAssignmentForm,
  comments: initializeCommentModal,
};

const getCallbackKeys = (containerEl) => {
  const keys = $(containerEl)
    .find("[data-callback]")
    .map((_, e) => $(e).data("callback"))
    .get();
  const rootCallback = $(containerEl).data("callback");
  if (rootCallback) {
    keys.push(rootCallback);
  }
  return keys;
};

const runFragmentCallbacks = (containerEl) => {
  const keys = getCallbackKeys(containerEl);
  const [found, missing] = partition(keys, (key) => key in CALLBACKS);
  found.forEach((key) => CALLBACKS[key]());
  missing.forEach((key) =>
    console.error(`No callback function found for ${key}`)
  );
};

$(document).on("ajaxComplete", function (event, xhr) {
  const isHTML = xhr.getResponseHeader("content-type").includes("text/html");
  if (isHTML) {
    const parsedHTML = $.parseHTML(xhr.responseText);
    parsedHTML.forEach((el) => runFragmentCallbacks(el));
  }
});
